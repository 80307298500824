import * as React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy - Sterea Medicina e Viaggi" />

      <div className="sterea-center-column-wrapper">
        <div className="sterea-center-column px-min py-12">
          <h2 className="mb-6">Privacy Policy</h2>

          <p>1. Oggetto del trattamento</p>
          <p>Il Titolare della Sterea Medicina e Viaggi di Quattrini Stellina tratta i dati personali, identificativi e non sensibili da Voi comunicati in occasione della registrazione al sito web stereamedicaltravel.it del Titolare (in seguito, &ldquo;Sito&rdquo;), della partecipazione a sondaggi di opinione e di gradimento, della compilazione di form di iscrizione tramite il Sito ad eventi organizzati dal titolare, della richiesta on-line di chiarimenti o richieste di supporto e dell&rsquo;invio di newsletter.</p>
          <p>​</p>
          <p>2. Finalit&agrave; del trattamento</p>
          <p>I Vostri dati personali sono trattati:</p>
          <p>A) senza il Vostro consenso espresso (art. 24 lett. a), b), c) Codice Privacy (D.Lgs. 196/03) e art. 6 lett. b), e) GDPR), per le seguenti Finalit&agrave; di Servizio:</p>
          <p>gestire e mantenere il Sito;</p>
          <p>permetterVi di fruire dei Servizi eventualmente da Voi richiesti;</p>
          <p>partecipare tramite il Sito ad iniziative organizzate dal Titolare (ad esempio, eventi);</p>
          <p>processare una richiesta di contatto;</p>
          <p>adempiere agli obblighi previsti dalla legge, da un regolamento, dalla normativa comunitaria o da un ordine dell&rsquo;Autorit&agrave;;</p>
          <p>adempiere obblighi connessi alla gestione dell&rsquo;associazione e dei rapporti con i soci;</p>
          <p>realizzare attivit&agrave; inerenti lo scopo statutario della Sterea Medicina e Viaggi di Stellina Quattrini di servizi a persone;</p>
          <p>prevenire o scoprire attivit&agrave; fraudolente o abusi dannosi per il Sito;</p>
          <p>esercitare i diritti del Titolare, ad esempio il diritto di esercizio di un diritto in sede giudiziaria.</p>
          <p>B) Solo previo Vostro specifico e distinto consenso (artt. 23 e 130 Codice Privacy e art. 7 GDPR), per le seguenti Altre Finalit&agrave;:</p>
          <p>inviarVi via e-mail sondaggi di opinione e di gradimento, newsletter e/o inviti ad eventi o iscriverVi ad eventi di cui &egrave; parte o che organizza il Titolare.</p>
          <p><br />3. Modalit&agrave; del trattamento</p>
          <p>Il trattamento dei Vostri dati personali &egrave; realizzato per mezzo delle operazioni indicate all&rsquo;art. 4 Codice Privacy e all&rsquo;art. 4 n. 2) GDPR e precisamente: raccolta, registrazione, organizzazione, conservazione, consultazione, elaborazione, modificazione, selezione, estrazione, raffronto, utilizzo, interconnessione, blocco, comunicazione, cancellazione e distruzione dei dati. I Vostri dati personali sono sottoposti a trattamento sia cartaceo che elettronico e/o automatizzato, mediante l&rsquo;uso di sito web ospitato su Cloud gestito dalla societ&agrave; Aruba Spa in Italia. Il Titolare tratter&agrave; i dati personali per il tempo necessario per adempiere alle finalit&agrave; di cui sopra e comunque per non oltre 10 anni dalla cessazione del rapporto per le Finalit&agrave; di Servizio e per non oltre 2 anni dalla raccolta dei dati per le Altre Finalit&agrave;.</p>
          <p>​</p>
          <p>4. Sicurezza</p>
          <p>Il Titolare ha adottato una gran variet&agrave; di misure di sicurezza per proteggere i Vostri dati contro il rischio di perdita, abuso o alterazione. In particolare: ha adottato le misure di cui agli artt. 32-34 Codice Privacy e all&rsquo;art. 32 GDPR; utilizza la tecnologia di criptaggio dei dati e i protocolli protetti di trasmissione dei dati (noti come HTTPS).</p>
          <p>​</p>
          <p>5. Accesso ai dati</p>
          <p>I Vostri dati potranno essere resi accessibili per le finalit&agrave; di cui all&rsquo;art. 2.A) e 2.B):</p>
          <p>a dipendenti e collaboratori del Titolare, nella loro qualit&agrave; di incaricati e/o responsabili interni del trattamento e/o amministratori di sistema;</p>
          <p>a societ&agrave; terze o altri soggetti (provider sito web, cloud provider, provider servizio e-payment, fornitori, tecnici addetti all&rsquo;assistenza hardware e software, spedizionieri e vettori, istituti di credito, studi professionali, etc.) che svolgono attivit&agrave; in outsourcing per conto del Titolare, nella loro qualit&agrave; di responsabili del trattamento.</p>
          <p><br />6. Comunicazione dei dati</p>
          <p>Senza il Vostro espresso consenso (ex art. 24 lett. a), b), d) Codice Privacy e art. 6 lett. b) e c) GDPR), il Titolare potr&agrave; comunicare i Vostri dati per le finalit&agrave; di cui all&rsquo;art. 2.A) a Organismi di vigilanza, Autorit&agrave; giudiziarie nonch&eacute; a tutti gli altri soggetti ai quali la comunicazione sia obbligatoria per legge per l&rsquo;espletamento delle finalit&agrave; dette. I Vostri dati non saranno diffusi.</p>
          <p>7. Trasferimento dati</p>
          <p>La gestione e la conservazione dei dati personali avverr&agrave; in (Italia / Europa), su server ubicati in Italia del Titolare e/o di societ&agrave; terze incaricate e debitamente nominate quali Responsabili del trattamento.</p>
          <p>8. Natura del conferimento dei dati e conseguenze del rifiuto di rispondere</p>
          <p>Il conferimento dei dati per le finalit&agrave; di cui all&rsquo;art. 2.A) &egrave; obbligatorio. In loro assenza, non potremo garantirVi n&eacute; la registrazione al Sito n&eacute; i Servizi dell&rsquo;art. 2.A).</p>
          <p>Il conferimento dei dati per le finalit&agrave; di cui all&rsquo;art. 2.B) &egrave; invece facoltativo. Potete quindi decidere di non conferire alcun dato o di negare successivamente la possibilit&agrave; di trattare dati gi&agrave; forniti: in tal caso, non potrete ricevere via e-mail inviti ad eventi, newsletter e sondaggi di opinione e di gradimento. In ogni caso continuerete ad avere diritto ai Servizi di cui all&rsquo;art. 2.A).</p>
          <p>9. Diritti dell&rsquo;interessato</p>
          <p>Nella Vostra qualit&agrave; di interessati, avete i diritti di cui all&rsquo;art. 7 Codice Privacy e art. 15 GDPR e precisamente i diritti di:</p>
          <p>ottenere la conferma dell'esistenza o meno di dati personali che Vi riguardano, anche se non ancora registrati, e la loro comunicazione in forma intelligibile;</p>
          <p>ottenere l'indicazione: a) dell'origine dei dati personali; b) delle finalit&agrave; e modalit&agrave; del trattamento; c) della logica applicata in caso di trattamento effettuato con l'ausilio di strumenti elettronici; d) degli estremi identificativi del titolare, dei responsabili e del rappresentante designato ai sensi dell'art. 5, comma 2 Codice Privacy e art. 3, comma 1, GDPR; e) dei soggetti o delle categorie di soggetti ai quali i dati personali possono essere comunicati o che possono venirne a conoscenza in qualit&agrave; di rappresentante designato nel territorio dello Stato, di responsabili o incaricati;</p>
          <p>ottenere: a) l'aggiornamento, la rettificazione ovvero, quando vi avete interesse, l'integrazione dei dati; b) la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, compresi quelli di cui non &egrave; necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti o successivamente trattati; c) l'attestazione che le operazioni di cui alle lettere a) e b) sono state portate a conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai quali i dati sono stati comunicati o diffusi, eccettuato il caso in cui tale adempimento si rivela impossibile o comporta un impiego di mezzi manifestamente sproporzionato rispetto al diritto tutelato;</p>
          <p>opporsi, in tutto o in parte: a) per motivi legittimi al trattamento dei dati personali che Vi riguardano, ancorch&eacute; pertinenti allo scopo della raccolta; b) al trattamento di dati personali che Vi riguardano a fini di invio di materiale pubblicitario o di vendita diretta o per il compimento di ricerche di mercato o di comunicazione commerciale, mediante l&rsquo;uso di sistemi automatizzati di chiamata senza l&rsquo;intervento di un operatore mediante e-mail e/o mediante modalit&agrave; di marketing tradizionali mediante telefono e/o posta cartacea. Si fa presente che il diritto di opposizione dell&rsquo;interessato, esposto al precedente punto b), per finalit&agrave; di marketing diretto mediante modalit&agrave; automatizzate si estende a quelle tradizionali e che comunque resta salva la possibilit&agrave; per l&rsquo;interessato di esercitare il diritto di opposizione anche solo in parte. Pertanto, l&rsquo;interessato pu&ograve; decidere di ricevere solo comunicazioni mediante modalit&agrave; tradizionali ovvero solo comunicazioni automatizzate oppure nessuna delle due tipologie di comunicazione.</p>
          <p>Ove applicabili, avete altres&igrave; i diritti di cui agli artt. 16-21 GDPR (Diritto di rettifica, diritto all&rsquo;oblio, diritto di limitazione di trattamento, diritto alla portabilit&agrave; dei dati, diritto di opposizione), nonch&eacute; il diritto di reclamo all&rsquo;Autorit&agrave; Garante.</p>
          <p>10. Modalit&agrave; di esercizio dei diritti</p>
          <p>Potrete in qualsiasi momento esercitare i diritti inviando:</p>
          <p>una raccomandata a.r. alla Sterea Medicina e Viaggi di Quattrini Stellina, Via Brodolini, 10,63821 Porto Sant'Elpidio (FM)</p>
          <p>una e-mail all&rsquo;indirizzo:&nbsp;<object width="300" height="0" data-mce-fragment="1"><a data-auto-recognition="true" href="mailto:info@stereamedicaltravel.it">info@stereamedicaltravel.it</a></object></p>
          <p>11. Minori</p>
          <p>Questo Sito e i Servizi del Titolare non sono destinati a minori di 18 anni e il Titolare non raccoglie intenzionalmente informazioni personali riferite ai minori. Nel caso in cui informazioni su minori fossero involontariamente registrate, il Titolare le canceller&agrave; in modo tempestivo, su richiesta degli utenti.</p>
          <p>12. Titolare, responsabile e incaricati</p>
          <p>Il Titolare del trattamento &egrave; Sterea Medicina e Viaggi di Quattrini Stellina con sede legale in Porto Sant'Elpidio, Via Brodolini, 10.<br />Responsabili esterni del trattamento: tutte le strutture necessarie alla finalit&agrave; del servizio, che possono trattare i Vostri dati anche autonomamente in qualit&agrave; di autonomi titolari per le finalit&agrave; della propria informativa.<br />L&rsquo;elenco aggiornato dei responsabili e degli incaricati al trattamento &egrave; custodito presso la sede del Titolare del trattamento.</p>
          <p>13. Modifiche alla presente Informativa</p>
          <p>La presente Informativa pu&ograve; subire variazioni. Si consiglia, quindi, di controllare regolarmente questa Informativa e di riferirsi alla versione pi&ugrave; aggiornata.</p>
          <p>Contatti:</p>
          <p>&nbsp;DPO/RPD :</p>
          <p><object width="300" height="0" data-mce-fragment="1"><a data-auto-recognition="true" href="mailto:info@stereamedicaltravel.it">info@stereamedicaltravel.it</a></object></p>
          <p>GARANTE PRIVACY:</p>
          <p>&nbsp;<object width="300" height="0" data-mce-fragment="1"><a data-auto-recognition="true" href="mailto:info@garanteprivacy.it">info@garanteprivacy.it</a></object></p>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;